<template>
    <section v-loading="loading" class="viviendas-crear-residente ml-4">
        <div class="row my-4">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ name: 'viviendas.residentes'}">
                        Viviendas
                    </el-breadcrumb-item>                    
                    <el-breadcrumb-item v-if="$route.params.id_residente">
                        Editar residente
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-else>
                        Nuevo residente
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-auto">
                <p v-if="$route.params.id_residente" class="f-20 f-500">Editar residente</p>
                <p v-else class="f-20 f-500">Nuevo residente</p>
            </div>
        </div>
        <ValidationObserver ref="validation">
            <div class="col-6">
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="Nombres" tag="div">
                            <p class="f-12 pl-2 label-inputs">Nombres</p>
                            <el-input v-model="residente.nombre" placeholder="Escribe tus nombres" show-word-limit maxlength="20" size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="Apellidos" tag="div">
                            <p class="f-12 pl-2 label-inputs">Apellidos</p>
                            <el-input v-model="residente.apellido" placeholder="Escribe sus apellidos" show-word-limit maxlength="20" size="small" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required" name="Fecha de Nacimiento" tag="div">
                            <p class="f-12 pl-2 label-inputs">Fecha de Nacimiento</p>
                            <el-date-picker v-model="residente.nacimiento" placeholder="Fecha de nacimiento" format="yyyy/MM/dd" value-format="yyyy-MM-dd" type="date" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="email" name="Correo" tag="div">
                            <p class="f-12 pl-2 label-inputs">Correo</p>
                            <el-input v-model="residente.correo" placeholder="Correo electrónico" show-word-limit maxlength="70" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|numeric|max:15" name="Teléfono" tag="div">
                            <p class="f-12 pl-2 label-inputs">Teléfono</p>
                            <el-input v-model="residente.telefono" placeholder="Teléfono" show-word-limit maxlength="15" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="contraseña" tag="div">
                            <p class="f-12 pl-2 label-inputs">Contraseña</p>
                            <el-input ref="contraseña" v-model="residente.password" placeholder="Contraseña" maxlength="20" type="password" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20|confirmed:contraseña" name="confirmar contraseña" tag="div">
                            <p class="f-12 pl-2 label-inputs">Confirmar contraseña</p>
                            <el-input v-model="residente.password_confirmation" placeholder="Confirmar contraseña" maxlength="20" type="password" size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <p class="f-12 pl-2 label-inputs">Grupo de viviendas</p>
                        <select-grupos ref="selectViviendasGrupo" @setAgrupacion="setAgrupacion" />  
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <p class="f-12 pl-2 label-inputs">Vivienda</p>
                        <ValidationProvider v-slot="{ errors }" rules="required" name="vivienda" tag="div">
                            <el-select v-model="residente.id_vivienda" placeholder="Seleccionar vivienda" size="small"
                                       class="w-100 mb-3"
                                       filterable    
                                       remote
                                       :remote-method="remoteMethod"
                            >
                                <el-option
                                v-for="(vivienda,index) in viviendas"
                                :key="index"
                                :label="vivienda.nombre"
                                :value="vivienda.id"
                                />
                            </el-select>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>   
                    </div>
                </div>
                <div class="d-flex justify-between">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required" name="Género" tag="div">
                            <p class="f-12 pl-2 label-inputs mb-2">Genero</p>
                            <div class="d-flex f-14 mt-1 my-auto">
                                <label class="d-middle mr-3" for="">
                                    <input v-model="residente.genero" :value="1" type="radio" class="option-input opt-gris radio mr-2" /> Otro
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <input v-model="residente.genero" :value="2" type="radio" class="option-input opt-gris radio mr-2" /> Hombre
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <input v-model="residente.genero" :value="3" type="radio" class="option-input opt-gris radio mr-2" /> Mujer
                                </label>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p> 
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required" name="Tipo de vivienda" tag="div">
                            <p class="f-12 pl-2 label-inputs mb-2">Tipo de Residente</p>
                            <div class="d-flex f-14 mt-1 my-auto">
                                <label class="d-middle mr-3" for="">
                                    <!-- <input v-model="residente.resident" :value="1" type="radio" class="option-input opt-gris radio mr-2" /> Funcionalidad residente -->
                                    <el-checkbox v-model="residente.residente" :true-label="1" :false-label="0" class="el-checkbox-negro">
                                        Funcionalidad residente
                                    </el-checkbox>
                                    
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <el-checkbox v-model="residente.propietario" :true-label="1" :false-label="0" class="el-checkbox-negro">
                                        Funcionalidad propietario
                                    </el-checkbox>
                                    <!-- <input v-model="residente.propietario" :value="1" type="radio" class="option-input opt-gris radio mr-2" /> Funcionalidad propietario -->
                                </label>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p> 
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            
            <div class="row mx-0 mt-4">
                <div class="col-12">
                    <button class="btn btn-general py-1 f-14 f-300 px-3" @click="guardar">
                        Guardar
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import viviendas from '~/router/viviendas/viviendas'
import Viviendas from '~/services/viviendas'
export default {
    data(){
        return{
            loading: false,
            residente:{
                id_usuario : null,
                nombre : '',
                apellido : '',
                nacimiento : '',
                id_vivienda : null,
                genero : 1,
                residente: 1,
                propietario: 0,
                password: null,
                password_confirmation: null,
                correo: '',
                telefono: null,
                loading: false
            },
            idGrupo: null,
            viviendas: [],
            editar: 0
        }
    },
    mounted(){
        if(this.$route.params.id_residente){
            this.editar = 1;
            this.obtenerResidente();
        }
        this.getViviendas()
    },
    methods:{
        setAgrupacion(id){
            if(id != this.idGrupo)this.residente.id_vivienda = null
            this.getViviendas(id)
            this.idGrupo = id
        },     
        async guardar(){
            try {
                let valid = await this.$refs.validation.validate()
                if(!valid) return this.notificacion('','Campos incompletos','warning')
                if (this.residente.correo == ''){return this.notificacion('', 'El campo de correo debe ser llenado')}
                if (this.residente.telefono == null || this.residente.telefono == ''){return this.notificacion('', 'El campo de teléfono debe ser llenado')}
                if (this.residente.propietario === 0 && this.residente.residente === 0) return this.notificacion('','Por favor seleccione el tipo de residente','warning');

                let payload = {
                    id_usuario : this.residente.id_usuario,
                    nombre : this.residente.nombre,
                    apellido : this.residente.apellido,
                    nacimiento : this.residente.nacimiento,
                    correo: this.residente.correo,
                    telefono: this.residente.telefono,
                    id_vivienda : this.residente.id_vivienda,
                    genero : this.residente.genero,
                    // arriendo : this.residente.arriendo,
                    residente: this.residente.residente ? this.residente.residente : '0',
                    propietario: this.residente.propietario ? this.residente.propietario : '0',
                    password: null,
                }

                if(this.residente.password != "*******") payload.password = this.residente.password

                Object.keys(payload).forEach((key) => {
                    if(!payload[key]) delete payload[key];
                })
          
                if(valid){
                    this.loading = true

                    if (this.editar == 1){
                        const { data } = await Viviendas.editarResidente(payload)
                        this.editar = 0;
                        if(data.success){
                            this.$notify({ title: 'Guardar Residente', message: 'Residente guardado correctamente', type: 'success' });
                            this.$router.push({ name: 'viviendas.residentes' })
                        }else{
                            this.$notify({ title: 'Guardar Residente', message: data.mensaje, type: 'warning' });
                        }
                    }else {
                        const { data } = await Viviendas.guardarResidente(payload)
                        if(data.success){
                            this.$notify({ title: 'Guardar Residente', message: 'Residente guardado correctamente', type: 'success' });
                            this.$router.push({ name: 'viviendas.residentes' })
                        }else{
                            this.$notify({ title: 'Guardar Residente', message: data.mensaje, type: 'warning' });
                        }
                    }

                    this.loading = false
                }

          
            } catch (e){
                this.loading = false
                this.$notify({
                    title: 'Error',
                    message: 'Todos los campos son requeridos',
                    type: 'warning'
                });
            }
        },
        async obtenerResidente(){
            this.loading = true
            try {
                let params = {
                    id_residente : this.$route.params.id_residente
                }
                const {data} = await Viviendas.obtenerResidente(params)
                if(data.success){
                    this.residente = {
                        id_usuario : data.data.id,
                        nombre : data.data.nombre,
                        apellido : data.data.apellido,
                        nacimiento : data.data.nacimiento,
                        id_vivienda : data.data.id_vivienda,
                        correo: data.data.correo,
                        telefono: data.data.telefono,
                        genero : data.data.genero,
                        // arriendo : data.data.arriendo,
                        password: null,
                        password_confirmation: null,
                        propietario : data.data.propietario,
                        residente : data.data.residente
                    }
                    
                    if(this.$route.params.id_residente){
                        this.residente.password = "*******"
                        this.residente.password_confirmation = "*******"
                    }
                    this.idGrupo = data.data.id_agrupacion
                    this.$refs.selectViviendasGrupo.setIdGrupo(data.data.id_agrupacion)
                }
                  
            } catch (e){
                console.log(e)
            }
            this.loading = false
        },
        async getViviendas(idPadre,search = null){      
            try {
                let params = {
                    id_padre: idPadre,
                    search: search
                }
                const {data} = await Viviendas.hijas(params)
                this.viviendas = data.data
            } catch (e){
                this.errorCatch(e)
            }
        },
        remoteMethod(search){
            setTimeout(() => {
                this.getViviendas(this.idGrupo,search)
            }, 100);
        },

    },
    
}
</script>
<style lang="scss" scoped>
</style>